import React from "react";
import "./Footer.component.css";

export default function Footer() {
  return (
    <footer className="footer">
      <section className="footer_section">
        <img
          alt="instaIcon"
          src="https://static.cdninstagram.com/rsrc.php/v3/yI/r/VsNE-OHk_8a.png"
        />
        <img
          alt="spotifyIcon"
          src="https://open.spotifycdn.com/cdn/images/favicon32.b64ecc03.png"
        />
        <img
          alt="instaIcon"
          src="https://static.cdninstagram.com/rsrc.php/v3/yI/r/VsNE-OHk_8a.png"
        />
        <img
          alt="spotifyIcon"
          src="https://open.spotifycdn.com/cdn/images/favicon32.b64ecc03.png"
        />
      </section>
    </footer>
  );
}
