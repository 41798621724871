// App.js (or your root component)
import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import reportWebVitals from './reportWebVitals';
import Main from './components/Main/Main';

const App = () => {
  const [pageSelected, setPageSelected] = useState('home'); // Initialize with 'home'

  const handlePageChange = (page) => {
    setPageSelected(page);
  };

  return (
    <React.StrictMode>
      <Header onPageChange={handlePageChange} />
      <Main selectedPage={pageSelected} />
      <Footer />
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Rest of your code...


reportWebVitals();