// Header.js
import React, { useState } from 'react';
import './Header.component.css';

export default function Header({ onPageChange }) {
  const handleItemClick = (page) => {
    onPageChange(page); // Update the selected page when an item is clicked
  };

  return (
    <header className="Header">
      <img className="headerLogo" alt="logo" src="logo/DIM.jpg" />
      <nav className="navbar">
        <ul className="unorderedListNavbar">
          <li onClick={() => handleItemClick('home')}>home</li>
          <li onClick={() => handleItemClick('presskit')}>presskit</li>
          <li onClick={() => handleItemClick('media')}>media</li>
          <li onClick={() => handleItemClick('contato')}>contato</li>
        </ul>
      </nav>
    </header>
  );
}
