// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Main Container styling */
.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Main Article styling */
#MainArticle {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(245, 245, 220, 0.2);
}
.paragraph {
  margin: 20px;
}
/* Media Query for Tablets */
@media screen and (min-width: 768px) {
  /* Adjust main container styling for tablets */
  .mainContainer {
    align-items: center;
  }

  /* Adjust main article styling for tablets */
  #MainArticle {
    width: 70%;
  }
}

/* Media Query for Notebooks and Monitors */
@media screen and (min-width: 1024px) {
  /* Adjust main container styling for notebooks and monitors */
  .mainContainer {
    align-items: center;
  }

  /* Adjust main article styling for notebooks and monitors */
  #MainArticle {
    width: 60%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Main/Main.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA,yBAAyB;AACzB;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,0CAA0C;AAC5C;AACA;EACE,YAAY;AACd;AACA,4BAA4B;AAC5B;EACE,8CAA8C;EAC9C;IACE,mBAAmB;EACrB;;EAEA,4CAA4C;EAC5C;IACE,UAAU;EACZ;AACF;;AAEA,2CAA2C;AAC3C;EACE,6DAA6D;EAC7D;IACE,mBAAmB;EACrB;;EAEA,2DAA2D;EAC3D;IACE,UAAU;EACZ;AACF","sourcesContent":["/* Main Container styling */\n.mainContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n/* Main Article styling */\n#MainArticle {\n  width: 90%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: rgba(245, 245, 220, 0.2);\n}\n.paragraph {\n  margin: 20px;\n}\n/* Media Query for Tablets */\n@media screen and (min-width: 768px) {\n  /* Adjust main container styling for tablets */\n  .mainContainer {\n    align-items: center;\n  }\n\n  /* Adjust main article styling for tablets */\n  #MainArticle {\n    width: 70%;\n  }\n}\n\n/* Media Query for Notebooks and Monitors */\n@media screen and (min-width: 1024px) {\n  /* Adjust main container styling for notebooks and monitors */\n  .mainContainer {\n    align-items: center;\n  }\n\n  /* Adjust main article styling for notebooks and monitors */\n  #MainArticle {\n    width: 60%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
