// Main.js
import React from 'react';
import './Main.css';
import HomeContent from './contents/HomeContent'; // Import it as a component
import MediaContent from './contents/MediaContent';
import PresskitContent from './contents/PresskitContent';
import ContatoContent from './contents/ContatoContent';
export default function Main({ selectedPage }) {
  // Use the selectedPage prop to conditionally render content
  return (
    <main className="mainContainer">
      <article id="MainArticle">
        {selectedPage === 'home' && <HomeContent />} {/* Use it as a component */}
        {selectedPage === 'presskit' && <PresskitContent />}
        {selectedPage === 'media' && <MediaContent />}
        {selectedPage === 'contato' && <ContatoContent />}
      </article>
    </main>
  );
}