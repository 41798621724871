// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer styling */
.footer {
  padding: 1rem;
  max-height: 150px;
  width: auto;
  text-align: center;
  background-color: rgba(21, 255, 0, 0.13);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -10px 15px 5px rgba(21, 255, 0, 0.13);
  margin-top: 30px;
}

/* Footer section styling */
.footer_section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

/* Footer section image styling */
.footer_section img {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.component.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,aAAa;EACb,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,qDAAqD;EACrD,gBAAgB;AAClB;;AAEA,2BAA2B;AAC3B;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA,iCAAiC;AACjC;EACE,kBAAkB;AACpB","sourcesContent":["/* Footer styling */\n.footer {\n  padding: 1rem;\n  max-height: 150px;\n  width: auto;\n  text-align: center;\n  background-color: rgba(21, 255, 0, 0.13);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 0px -10px 15px 5px rgba(21, 255, 0, 0.13);\n  margin-top: 30px;\n}\n\n/* Footer section styling */\n.footer_section {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  align-content: center;\n}\n\n/* Footer section image styling */\n.footer_section img {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
